import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout/Layout";
import ProductRow from "../components/cart/ProductRow";
import { Link, navigate } from "gatsby";
import { useStore } from "../context/StoreContext";

const Cart = () => {
  const { cart, removeLineItem } = useStore();
  const [totalPrice, setTotalPrice] = useState(0);
  const [cgvAccepted, setCgvAccepted] = useState(false);


  useEffect(() => {
    if (cart && cart.cost) {
      setTotalPrice(parseFloat(cart.cost.subtotalAmount.amount));
    }
  }, [cart]);

  const handleInputChange = () => {
    setCgvAccepted(!cgvAccepted);
  };

  const goToCheckout = () => {
    if (cgvAccepted && cart.checkoutUrl) {
      navigate(cart.checkoutUrl);
    } else {
      console.error("checkout Url not found, refresh page plz")
      alert("Erreur : URL de checkout non trouvé, rafraichissement de la page nécessaire.")
      window.reload()
    }
  };

  return (
    <Layout>
      <div className="content">
        <section className="section section--gradient">
          <div className="container">
            <h2 className="is-flex is-justify-content-center is-centered is-bold" style={{ marginBottom: "4rem" }}>
              Votre panier
            </h2>

            {cart?.lines?.edges.length === 0 ? (
              <>
                <div className="is-flex is-justify-content-center is-centered">
                  <div className="column">
                    <div className="row is-flex is-justify-content-center is-centered" style={{ marginBottom: "4rem" }}>
                      <p>Votre panier est vide.</p>
                    </div>
                    <div className="row is-flex is-justify-content-center is-centered">
                      <Link className="button has-text-terracota is-link" style={{ backgroundColor: "black", marginTop: "2rem" }} to={"/atelier-type"}>
                        Voir les ateliers
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Wrapper>
                <HeaderWrapper>
                  <Text className="is-centered" style={{ textAlign: "center" }}>Produit(s)</Text>
                  <Text className="has-text-right is-hidden-mobile mobile-hidden">Prix</Text>
                  <Text className="has-text-right is-hidden-mobile mobile-hidden">Quantité</Text>
                  <Text className="has-text-right cart-total-mobile">Total</Text>
                </HeaderWrapper>

                {cart?.lines?.edges.map(({ node }) => (
                  <ProductRow
                    key={node.id}
                    item={node}
                    removeLineItem={() => removeLineItem(node.id)}
                  />
                ))}

                <ButtonWrapper>
                  <div className="rows">
                    <div className="row">
                      <label className="" style={{ marginTop: "20px" }}>
                        J'accepte les <Link to="/conditions-de-vente"><span className="has-text-terracota">conditions générales de vente</span></Link>
                      </label>
                      <input
                        style={{ width: "50px", marginBottom: "10px" }}
                        type="checkbox"
                        checked={cgvAccepted}
                        onChange={handleInputChange}
                        className="input-quantity"
                      />
                    </div>

                    <div className="row">
                      <h5 className="has-text-right" style={{ margin: 0 }}>Sous-total : {totalPrice} €</h5>
                      <p className="is-size-6 has-text-right" style={{ color: "grey", fontStyle: "italic", marginBottom: "0.5rem" }}>Taxes incluses.</p>
                    </div>

                    <div className="row has-text-right">
                      <button
                        className="button has-text-terracota is-link"
                        disabled={!cgvAccepted}
                        style={{ backgroundColor: "black", marginTop: "20px" }}
                        onClick={goToCheckout}
                      >
                        Passer à la caisse
                      </button>
                    </div>
                  </div>
                </ButtonWrapper>
              </Wrapper>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Cart;

const Wrapper = styled.div`
  margin: 1rem;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  gap: 0.5rem;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  min-width: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
