import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import paragraphs from "lines-to-paragraphs";
import { useStore } from "../../context/StoreContext";

const ProductRow = ({ item }) => {
  const { cart, removeLineItem } = useStore();

  const [descriptionHtml, setDescriptionHtml] = useState("");
  const [handle, setHandle] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (cart && cart.lines) {
      cart.lines.edges.forEach(({ node }) => {
        if (node.merchandise.id === item.merchandise.id) {
          setDescriptionHtml(node.merchandise.product.descriptionHtml);
          setHandle(node.merchandise.product.handle);
        }
      });
    }

    if (item.customAttributes && item.customAttributes.length > 0) {
      const commentAttribute = item.customAttributes.find(attr => attr.key === "Comment");
      if (commentAttribute) {
        setComment(commentAttribute.value);
      }
    }
  }, [cart]);

  const variantId = item.merchandise?.id;
  const title = item.merchandise?.product?.title || "Produit inconnu";

  const quantity = item.quantity;
  const price = item.merchandise?.price.amount;
  const image = item.merchandise?.image?.src || item.merchandise.product?.featuredImage?.url || "/placeholder.jpg";


  return (
    <Wrapper>
      <ProductWrapper>
        <div className="columns is-full">
          <Link to={"/ateliers/" + handle}>
            <Image src={image} alt={title} style={{ margin: "1rem" }} />
          </Link>
          <div className="column is-justify-content-center">
            <Subtitle>
              <Link to={"/ateliers/" + handle} style={{ marginTop: 10, width: "100px", color: "black" }}>
                {title}
              </Link>
            </Subtitle>
            <Subtitle>
              <div dangerouslySetInnerHTML={{ __html: paragraphs(descriptionHtml || "") }} style={{ marginTop: 10 }} />
            </Subtitle>
            {comment.length > 0 &&
            <Subtitle>
              <p>Commentaires : {comment}</p>
            </Subtitle>
            }
            <DeleteButton onClick={() => removeLineItem(item.id)}>
              Retirer du panier
            </DeleteButton>
          </div>
        </div>
      </ProductWrapper>
      <Subtitle className="has-text-right mobile-hidden is-hidden-mobile">{price} €</Subtitle>
      <Subtitle className="has-text-right mobile-hidden is-hidden-mobile">{quantity}</Subtitle>
      <Subtitle className="has-text-right">{(quantity * price).toFixed(2)} €</Subtitle>
    </Wrapper>
  );
};

export default ProductRow;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

const ProductWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  align-items: center;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  min-width: 50px;
`;

const DeleteButton = styled.p`
  color: #A8563A;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
`;
